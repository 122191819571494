<template>
   <div class="keyControl" :class="{'w423':activeMount}" v-if="keyControlFlag">
      <div class="head">
        <div class="tl title">机身控制</div>
        <div class="tl title" v-if="activeMount" :title='activeMount.gimbalName'>挂载控制</div>
        <div class="tr f12 cp" @click="$emit('close')">关闭</div>
      </div>
      <div class="content">
        <div>
          <div>
            <div
              :class="{ active: keycode == 'q' }"
              title="机身左转"
              @mousedown="clickControl({ key: 'q' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share.png" alt="" />
              Q
            </div>
            <div
              :class="{ active: keycode == 'w' }"
              title="前进"
              @mousedown="clickControl({ key: 'w' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share2.png" alt="" />
              W
            </div>
            <div
              :class="{ active: keycode == 'e' }"
              title="机身右转"
              @mousedown="clickControl({ key: 'e' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share3.png" alt="" />
              E
            </div>
            <div
              class="ml12"
              :class="{ active: keycode == 'c' }"
              title="上升"
              @mousedown="clickControl({ key: 'c' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share7.png" alt="" />
              C
            </div>
          </div>
          <div class="mt16">
            <div
              :class="{ active: keycode == 'a' }"
              title="左飞"
              @mousedown="clickControl({ key: 'a' })"
              @mouseup="cancelControl()"
            >
              A
              <img src="./img/share4.png" alt="" />
            </div>
            <div
              :class="{ active: keycode == 's' }"
              title="后退"
              @mousedown="clickControl({ key: 's' })"
              @mouseup="cancelControl()"
            >
              S
              <img src="./img/share5.png" alt="" />
            </div>
            <div
              :class="{ active: keycode == 'd' }"
              title="右飞"
              @mousedown="clickControl({ key: 'd' })"
              @mouseup="cancelControl()"
            >
              D
              <img src="./img/share6.png" alt="" />
            </div>
            <div
              class="ml12"
              :class="{ active: keycode == 'z' }"
              title="下降"
              @mousedown="clickControl({ key: 'z' })"
              @mouseup="cancelControl()"
            >
              Z
              <img src="./img/share8.png" alt="" />
            </div>
          </div>
        </div>
        <div class="ml12">
          <div>
            <div :class="{active: keycode == 'j'}" title="急停" @click="clickControl({key:'j'})">J</div>
          </div>
        </div>
        
        <div v-show="activeMount" class="line ml12"></div>
        <div v-show="activeMount" class="ml12">
          <div class="c_t">
            <div :class="{active: keycode == 'r'}" title="回中" @click="clickControl({key:'r'})">R</div>
            <!-- MMC_Gimbal_ZT60R支持 MMC_Gimbal_ZT60支持 -->
            <div v-if="isShow"  :class="{active: keycode == 'g'}" title="垂直向下" @click="clickControl({key:'g'})">G</div>
          </div>
        </div>
        <div v-show="activeMount" class="ml12">
          <div>
            <div :class="{active: keycode == 'ArrowUp'}" title="镜头向上" @mousedown="clickControl({key:'ArrowUp'})" @mouseup="cancelControl">
              <img src="./img/向上.png" alt="">
            </div>
          </div>
          <div>
            <div :class="{active: keycode == 'ArrowLeft'}" title="镜头向左" @mousedown="clickControl({key:'ArrowLeft'})" @mouseup="cancelControl">
              <img src="./img/向左.png" alt="">
            </div>
            <div :class="{active: keycode == 'ArrowDown'}" title="镜头向下" @mousedown="clickControl({key:'ArrowDown'})" @mouseup="cancelControl">
              <img src="./img/向下.png" alt="">
            </div>
            <div :class="{active: keycode == 'ArrowRight'}" title="镜头向右" @mousedown="clickControl({key:'ArrowRight'})" @mouseup="cancelControl">
              <img src="./img/向右.png" alt="">
            </div>
          </div>
        </div>
        <!-- <div class="ml12">
          <div>
            <div class="f24" :class="{active: keycode == '-'}" title="变焦减小" @mousedown="clickControl({key:'-'})" @mouseup="cancelControl">-</div>
          </div>
          <div class="mt16">
            <div class="f24" :class="{active: keycode == '+' || keycode == '='}" title="变焦增加" @mousedown="clickControl({key:'+'})" @mouseup="cancelControl">+</div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
</template>
<script>
import * as mountCtrlList from '@/components/mount/utils.js';
export default {
    data() {
        return {
            mountCtrlList ,
            keycode: '',
            keyControlFlag: true,
            isShow:false,
            controlSet: null,
            // 当前操作挂载
            activeMount:null,
            // 已适配高清变焦相机挂载
            mount:['MMC_Gimbal_ZT60R','MMC_Gimbal_Z60R','MMC_Gimbal_ZT30N','MMC_Gimbal_Z30Pro','MMC_Gimbal_Z40','MMC_Gimbal_Z40N','MMC_Gimbal_Z40S','DJI_H20T','MMC_Gimbal_ZT1','MMC_Gimbal_Z33N','MMC_Gimbal_Z20','MMC_Gimbal_P20','MMC_Gimbal_P30']
        }
    },
    computed: {
      mountList() {
      return this.$store.state.uavApplications.mountList;
    },
    selectMount() {
      return this.$store.state.uavApplications.selectMount;
    },

  },
    mounted() {
        this.keyControl();
     let activeMount =   this.mountList.filter(item => this.mount.includes(item.gimbalName))
        if(activeMount){
          this.activeMount =activeMount[0]
          if(this.activeMount){
            if(this.activeMount.gimbalName=='MMC_Gimbal_ZT60R'||this.activeMount.gimbalName=='MMC_Gimbal_Z60R'){
          this.isShow =true
        }else{
          this.isShow =false
        }
          }
        }
    },
    beforeDestroy() {
        // this.keyControlFlag=false
    },
    methods: {
        keyControl() {
      let data = null;
      let num = 0;
      document.addEventListener("keydown", (event) => {
        if (this.keyControlFlag) {
          this.keycode = event.key;
          //return
          if (!(num % 10)) {
            if (event.key == "q" || event.keyCode == 81) {
              data = {
                  leftTurn: 10,
              };
            } else if (event.key == "w" || event.keyCode == 87) {
              data = {
                front: 100,
              };
            } else if (event.key == "e" || event.keyCode == 69) {
              data = {
                  rightTurn: 10,
              };
            } else if (event.key == "a" || event.keyCode == 65) {
              data = {
                  left: 100,
              };
             
            } else if (event.key == "s" || event.keyCode == 83) {
              data = {
                  back: 100,
              };
            } else if (event.key == "d" || event.keyCode == 68) {
              data = {
                  right: 100,
              };
            } else if (event.key == "c" || event.keyCode == 67) {
              data = {
                  up: 100,
              };
            } else if (event.key == "z" || event.keyCode == 90) {
              data = {
                  down: 100,
              };
            }
          }
          if(data){
            this.$store.dispatch("uavApplications/keyControl", data);
          }
          // 急停
          if (event.key == "j" || event.keyCode == 74) {
              this.keycode = "";
              clearInterval(this.controlSet);
              this.controlSet = null;
          }
          this.controlMount()
        }
        num++;
      });
      document.addEventListener("keyup", (event) => {
        this.keycode = "";
        this.stopGimbal()
      });
    },
    controlMount(){
      if(this.activeMount){
            let mount = this.mountCtrlList[this.activeMount.gimbalName]
            let buffer=null
                // 垂直向下
                if (event.key == "g" || event.keyCode == 71) {
                  buffer =mount.change_control_mode(2)
                  
                }else if (event.key == "r" || event.keyCode == 82) {
                  // 回中
                  buffer = mount.gimbal_mode_ctrl(2)
                }else if (event.key == "" || event.keyCode == 32) {
                }else if(event.key == "-" || event.keyCode == 109){
                  // let nums = localStorage.getItem(this.device.deviceList[0].deviceHardId) || 5
                  if(nums >= 2){
                    // this.$refs.mountRef.handle_zoom_reduce()
                    // this.$refs.mountRef.stopChange()
                  }
                }else if(event.key == "+" || event.keyCode == 107){
                  // let nums = localStorage.getItem(this.device.deviceList[0].deviceHardId) || 5
                  // if(nums <= 200){
                  //   this.$refs.mountRef.handle_zoom_plus()
                  //   this.$refs.mountRef.stopChange()
                  // }
                }else if (event.keyCode === 37 || event.key === "ArrowLeft") { //  适配 MMC_Gimbal_ZT60R
                  buffer = mount.gimbal_yaw_ctrl(-1)
                } else if (event.keyCode === 38 || event.key === "ArrowUp") {
                  buffer = mount.gimbal_pitch_ctrl(1)
                } else if (event.keyCode === 39 || event.key === "ArrowRight") {
                  buffer = mount.gimbal_yaw_ctrl(1)
                } else if (event.keyCode === 40 || event.key === "ArrowDown") {
                  buffer = mount.gimbal_pitch_ctrl(-1)
                }
                if(buffer){
                  this.$store.dispatch('uavApplications/mounteControl', {
                  mountId:this.activeMount.mountId,
                  payload: buffer,
                  callback: (isOk) => {
                    isOk && console.log('发出控制挂载指令',buffer);
                  }
      });
                }
            }
    },
    clickControl(event) {
      let data = null;
      let num = 0;
      this.controlSet = setInterval(() => {
        if (this.keyControlFlag) {
          this.keycode = event.key;
          // return
          if (!(num % 10)) {
            if (event.key == "q" || event.keyCode == 81) {
              data = {
                leftTurn: 10,
              };
            } else if (event.key == "w" || event.keyCode == 87) {
              data = {
                front: 100,
              };
            } else if (event.key == "e" || event.keyCode == 69) {
              data = {
                rightTurn: 10,
              };
            } else if (event.key == "a" || event.keyCode == 65) {
              data = {
                left: 100,
              };
            } else if (event.key == "s" || event.keyCode == 83) {
        
              data = {
                back: 100,
              };
            } else if (event.key == "d" || event.keyCode == 68) {
              data = {
                right: 100,
              };
            } else if (event.key == "c" || event.keyCode == 67) {
              data = {
                up: 100,
              };
            } else if (event.key == "z" || event.keyCode == 90) {
              data = {
                down: 100,
              };
            }
          }
          this.$store.dispatch("uavApplications/keyControl", data);
       if (event.key == "j" || event.keyCode == 74) {+ 0
            setTimeout(() => {
              this.keycode = "";
              clearInterval(this.controlSet);
              this.controlSet = null;
            }, 500);
          }
          
          this.controlMount()
        }
        num++;
      }, 300);
    },
    stopGimbal(){
      if(this.activeMount){
        let mount = this.mountCtrlList[this.activeMount.gimbalName]
        this.$store.dispatch('uavApplications/mounteControl', {
        mountId:this.activeMount.mountId,
        payload:  mount.gimbal_yaw_ctrl(0),
        callback: (isOk) => {
          isOk && console.log('发出控制挂载指令');
        }
      });
      this.$store.dispatch('uavApplications/mounteControl', {
        mountId:this.activeMount.mountId,
        payload: mount.gimbal_pitch_ctrl(0),
        callback: (isOk) => {
          isOk && console.log('发出控制挂载指令');
        }
      });
      }
    },
    cancelControl() {
      this.keycode = "";
      clearInterval(this.controlSet);
      this.controlSet = null;
      this.stopGimbal()
    },
    },
}
</script>
<style lang="scss" scoped>
.keyControl {
  position: fixed;
  right: 6px;
  bottom: 157px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 210px;
  height: 120px;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px;
  padding: 8px;
  .head {
    width: 100%;
    color: #fff;
    text-indent: 16px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .title {
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: YouSheBiaoTiHei;
      font-size: 18px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    > div {
      flex-direction: column;
      display: flex;
      align-items: center;
      > div {
        display: flex;
        div {
          background: rgba(9, 32, 87, 0);
          width: 32px;
          height: 32px;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .active {
          background: #631cce;
        }
      }
    }
    .line {
      width: 1px;
      height: 60px;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
    }
  }
}
</style>