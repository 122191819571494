<template>
  <div>
    <div class="airway-popup">
      <div v-interact class="header">
        <span v-hover class="iconfont icon-guanbi" @click="close" />
      </div>
      <div class="tabs">
        <span class="tab_box left">航线编辑</span>
      </div>
      <div v-if="active == 0" class="content_box left">
        <el-form ref="form" :model="form" :rules="rules" label-width="85px">
          <el-form-item label="航线名称" prop="name">
            <div class="required">*</div>
            <el-input v-model.trim="form.name" readonly />
          </el-form-item>
          <el-form-item label="默认高度" prop="height">
            <el-input-number v-model.trim="form.height" :controls="false" clearable />
            m
          </el-form-item>
          <el-form-item label="默认速度" prop="speed">
            <el-input-number v-model.trim="form.speed" :controls="false" clearable />
            m/s
          </el-form-item>
          <el-form-item label="默认偏航角" prop="yaw">
            <el-input-number v-model.trim="form.yaw" :controls="false" clearable />
            (&deg;)
          </el-form-item>
          <el-form-item label="默认俯仰角" prop="pitch">
            <el-input-number v-model.trim="form.pitch" :controls="false" clearable />
            (&deg;)
          </el-form-item>
          <el-form-item label="绘制航点" required>
            <el-button
              v-hover
              class="draw-btn"
              :class="{
                active: isDraw
              }"
              @click="editPoint"
            >
              <div class="iconfont icon-shengchenghangxian" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="footer">
        <button v-hover class="left btn" @click="changeRoute">保存</button>
        <button v-hover class="right btn" @click="clearHandle">重置</button>
      </div>
    </div>
    <div>
      <WaypointChange
        v-if="isShowWaypointPopup"
        :uuid="uuid"
        :point-info="pointInfo"
        :points-info="pointsInfo"
        :current-point-num="currentPointNum"
        @waypointPopupClose="waypointPopupClose"
        @changePointInfo="changePointInfo"
        @deletePointInfo="deletePointInfo"
      />
    </div>
  </div>
</template>

<script>
import { AirLine } from '@/api';
import { Utils } from '@/utils/cesium';
import { nanoid } from 'nanoid';
import WaypointChange from '@/components/home/airway-edit/waypoint-change';

let dataSrouce;
let handler;
let positions = [];
let isDrawEdit = false;
let currentPoint;
let pointIndex;
let airLineInfo;

// 动作类型
const ACTION_TYPE_MAP = {
  photo: 'START_TAKE_PHOTO',
  video: 'START_RECORD',
  hover: 'STOP_RECORD'
};

const ACTION_TYPE_MAP_GET_KEY = {
  START_TAKE_PHOTO: 'photo',
  START_RECORD: 'video',
  STOP_RECORD: 'hover',
  STAY: 'hover'
};

const ACTION_TYPE_MAP_GET_LABEL = {
  START_TAKE_PHOTO: '拍照',
  START_RECORD: '录像',
  STOP_RECORD: '悬停',
  STAY: '悬停'
};

const ACTION_TYPE_MAP_GET_UNIT = {
  START_TAKE_PHOTO: '张',
  START_RECORD: 's',
  STOP_RECORD: 's',
  STAY: 's'
};

export default {
  inject: ['change_active', 'route_info', 'point_info'],
  components: {
    WaypointChange
  },
  data() {
    const validateName = (rule, value, callback) => {
      const regEn = /[`~!@#$%^&*_+<>?:"{},.\/;'[\]]/im.test(value);
      if (!value) {
        callback(new Error('请输入航线名称！'));
      } else if (regEn) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validateHeight = (rule, value, callback) => {
      const isnum = /^[0-9]+.{0,1}[0-9]{0,2}$/.test(value);
      if (!value) {
        callback(new Error('请输入默认高度！'));
      } else if (value < 50 || value > 150) {
        callback(new Error('高度限制50m-150m！'));
      } else if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validateSpeed = (rule, value, callback) => {
      const isnum = /^[0-9]+.{0,1}[0-9]{0,2}$/.test(value);
      if (!value) {
        callback(new Error('请输入默认速度！'));
      } else if (value > 26 || value < 3) {
        callback(new Error('速度限制3m/s-26m/s！'));
      } else if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validateYaw = (rule, value, callback) => {
      const isnum = /^[+-]?\d+(\.\d+)?$/.test(value);
      if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validatePitch = (rule, value, callback) => {
      const isnum = /^[+-]?\d+(\.\d+)?$/.test(value);
      if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        name: [{ validator: validateName, trigger: ['change'] }],
        height: [{ validator: validateHeight, trigger: ['change'] }],
        speed: [{ validator: validateSpeed, trigger: ['change'] }],
        yaw: [{ validator: validateYaw, trigger: ['change'] }],
        pitch: [{ validator: validatePitch, trigger: ['change'] }]
      },
      active: 0,
      form: {
        name: '',
        height: 100,
        speed: 3,
        yaw: 0,
        pitch: 0
      },
      routeInfo: {},

      isShowWaypointPopup: false, // 编辑窗口
      pointInfo: {}, // 当前点
      pointsInfo: [], // 所有点
      isDraw: false, // 开关
      uuid: '',
      currentPointNum: 1 // 第几个点
    };
  },

  mounted() {
    this.getRouteInfo();
  },

  destroyed() {
    this.close();
    this.waypointPopupClose();
  },
  methods: {
    // 重置
    clearHandle() {
      this.form = {
        name: this.routeInfo.name,
        height: 100,
        speed: 3,
        yaw: 0,
        pitch: 0
      };
      if (dataSrouce) {
        dataSrouce.entities.removeAll();
        viewer.dataSources.remove(dataSrouce, true);
        dataSrouce = null;
        positions = [];
        this.pointsInfo = [];
      }
      handler = handler && handler.destroy();
    },

    async changeRoute() {
      const that = this;
      this.$refs['form'].validate((validate) => {
        if (validate) {
          if (that.pointsInfo.length) {
            const params = that.pointsInfo.map((item) => {
              const actions = item.actions.map((subItem) => {
                return {
                  type: ACTION_TYPE_MAP[subItem.key],
                  takeSecond: subItem.value
                };
              });
              return { ...item, actions };
            });

            AirLine.Change({ ...this.form, points: params }).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功！',
                  duration: 1000
                });
                that.close();
              } else {
                this.$message.error(res.msg || '航线修改失败');
              }
            });
          } else {
            this.$message({
              type: 'warning',
              message: '请规划航线！',
              duration: 1000
            });
          }
        }
      });
    },

    close() {
      this.change_active(1);
      if (dataSrouce) {
        dataSrouce.entities.removeAll();
        viewer.dataSources.remove(dataSrouce, true);
        dataSrouce = null;
        positions = [];
      }
    },

    editPoint() {
      this.drawPoints();
    },

    waypointPopupClose() {
      this.isDraw = false;
      this.isShowWaypointPopup = false;
      handler = handler && handler.destroy();
    },

    async getRouteInfo() {
      const lineObj = this.route_info();
      const param = lineObj.name;
      await AirLine.routeDetail(param).then((resp) => {
        this.routeInfo = resp.data;
        this.form.name = this.routeInfo.name;
        this.pointsInfo = resp.data.points.map((item) => {
          const actions = item.actions.map((subItem, index) => {
            return {
              checked: true,
              id: Number(index + 1),
              value: subItem.takeSecond,
              key: ACTION_TYPE_MAP_GET_KEY[subItem.type],
              label: ACTION_TYPE_MAP_GET_LABEL[subItem.type],
              unit: ACTION_TYPE_MAP_GET_UNIT[subItem.type]
            };
          });
          return { ...item, actions };
        });
        this.showRoute(resp.data.points);
      });
    },

    async drawPoints() {
      if (this.isDraw) return;
      this.isDraw = true;

      const viewer = window.viewer;

      // 禁止规划航线时双击放大地图
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      // if (dataSrouce) {
      //   dataSrouce.entities.removeAll()
      //   viewer.dataSources.remove(dataSrouce, true)
      //   dataSrouce = null
      //   positions = []
      // }

      // 向viewer添加一个数据源管理
      if (!dataSrouce) {
        dataSrouce = new Cesium.CustomDataSource('airway_edit');
        await viewer.dataSources.add(dataSrouce);
      }

      const entities = dataSrouce.entities;
      +entities.add({
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return this.pointsInfo.map((item) => {
              return Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, 0);
            });
          }, false)
        }
      });

      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      // 对鼠标按下事件的监听
      handler.setInputAction(async (event) => {
        const windowPosition = event.position;
        const pickedObject = viewer.scene.pick(windowPosition);
        const position = Utils.getCartesian3FromPX(viewer, event.position);
        const { latitude, longitude } = this.cartesianToLatLng(position);

        if (Cesium.defined(pickedObject)) {
          document.documentElement.style.cursor = 'move';
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          viewer.scene.screenSpaceCameraController.enableZoom = false;

          isDrawEdit = true;
          // let entity = pickedObject.id
          currentPoint = pickedObject.id;
          pointIndex = this.pointsInfo.findIndex((item) => item.uuid === currentPoint.id);
          this.uuid = currentPoint.id;
          this.currentPointNum = String(pointIndex + 1);
          this.pointInfo = this.pointsInfo[pointIndex];
          this.pointInfo.longitude = longitude;
          this.pointInfo.latitude = latitude;
        } else {
          positions.push(position);
          const uuid = nanoid();
          const point = {
            uuid,
            latitude,
            longitude,
            altitude: this.form.height,
            yawAngle: this.form.yaw,
            pitchAngle: this.form.pitch,
            speed: this.form.speed,
            actions: []
          };
          this.uuid = uuid;
          this.pointInfo = point;
          this.pointsInfo.push(point);
          const index = this.pointsInfo.findIndex((item) => item.uuid === uuid);
          this.currentPointNum = String(index + 1);
          airLineInfo = this.pointsInfo;
          this.createPoint(uuid, point, airLineInfo);
        }

        // 控制点信息弹窗
        if (!this.isShowWaypointPopup) {
          this.isShowWaypointPopup = true;
        }
        console.log('点位-->', this.pointInfo);
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      // 对鼠标移动事件的监听
      handler.setInputAction((event) => {
        if (isDrawEdit && currentPoint) {
          const cartesian = Utils.getCartesian3FromPX(viewer, event.startPosition);

          if (cartesian && pointIndex > -1) {
            const currentWgs84 = Utils.transformCartesian2WGS84(cartesian);
            this.pointsInfo[pointIndex].longitude = currentWgs84.lng;
            this.pointsInfo[pointIndex].latitude = currentWgs84.lat;
            this.pointInfo.longitude = currentWgs84.lng;
            this.pointInfo.latitude = currentWgs84.lat;
            currentPoint.position = Cesium.Cartesian3.fromDegrees(
              currentWgs84.lng,
              currentWgs84.lat,
              0
            );
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // 对鼠标抬起事件的监听
      handler.setInputAction(() => {
        pointIndex = null;
        isDrawEdit = false;
        currentPoint = undefined;
        setTimeout(() => {
          document.documentElement.style.cursor = 'default';
          viewer.scene.screenSpaceCameraController.enableRotate = true;
          viewer.scene.screenSpaceCameraController.enableZoom = true;
        }, 300);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },

    async showRoute(arr) {
      const polyline = arr;
      const viewer = window.viewer;

      // 禁止规划航线时双击放大地图
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      if (!dataSrouce) {
        dataSrouce = new Cesium.CustomDataSource('airway_edit');
        await viewer.dataSources.add(dataSrouce);
      }

      polyline.forEach((item, index) => {
        const position = Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, 0);
        positions.push(position);
        this.createPoint(item.uuid, item, arr);
      });

      const entities = dataSrouce.entities;

      const redLine = entities.add({
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return this.pointsInfo.map((item) => {
              return Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, 0);
            });
          }, false)
        }
      });
      viewer.flyTo(redLine);
    }, // showRoute结束

    createPoint(uuid, position, points) {
      if (dataSrouce) {
        const entities = dataSrouce.entities;
        entities.add({
          name: 'airline_point',
          id: uuid,
          position: Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, 0),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            fillColor: Cesium.Color.RED
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
          },
          label: {
            text: new Cesium.CallbackProperty(() => {
              const index = points.findIndex((item) => item.uuid === uuid);
              return String(index + 1);
            }, false),
            scale: 0.5,
            font: 'bold 24px Microsoft YaHei',
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0
          }
        });
      }
    }, // createPoint结束

    cartesianToLatLng(cartesian) {
      const cartographic = Cesium.Cartographic.fromCartesian(
        cartesian,
        viewer.scene.globe.ellipsoid,
        new Cesium.Cartographic()
      );
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const altitude = cartographic.height;
      return { latitude, longitude, altitude };
    }, // cartesianToLatLng结束

    changePointInfo(pointInfo) {
      const index = this.pointsInfo.findIndex((item) => item.uuid === pointInfo.uuid);
      this.pointsInfo[index] = pointInfo;
    },

    deletePointInfo(uuid) {
      const index = this.pointsInfo.findIndex((item) => item.uuid === uuid);
      if (index > -1) {
        this.pointsInfo.splice(index, 1);
        dataSrouce.entities.removeById(uuid);
        this.pointInfo = this.pointsInfo[this.pointsInfo.length - 1];

        this.currentPointNum = String(this.pointsInfo.length);

        if (this.pointsInfo.length == 0) {
          this.pointInfo = {};
          this.currentPointNum = '0';
          this.isShowWaypointPopup = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.airway-popup {
  width: 323px;
  height: 441px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  top: 200px;
  left: 200px;
  .header {
    height: 32px;
    .iconfont {
      font-size: 24px;
      color: #b3bbc5;
      float: right;
      margin: 5px 5px 0 0;
    }
  }
  .tabs {
    height: 32px;
    background: #111111;
    box-sizing: border-box;
    .active {
      background: #1e2026;
    }
    .tab_box {
      display: inline-block;
      width: 80px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #c6ccd4;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        background: #1e2026;
      }
    }
  }
  .content_box {
    border-bottom: 1px solid #494949;
    height: calc(100% - 116px);
    box-sizing: border-box;
    overflow: auto;
    padding: 10px 10px 0;
    .top {
      display: flex;
      justify-content: space-between;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      padding-bottom: 8px;
      border-bottom: 1px solid #494949;
      .left {
        .symbol {
          font-size: 12px;
          color: #ff375f;
        }
        .hint {
          opacity: 0.5;
          font-size: 12px;
          color: #b3bbc5;
        }
      }
      .el-button {
        width: 77px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        padding: 0;
        font-size: 14px;
        color: #f8f8f8;
        text-align: center;
      }
    }
  }
  .el-form {
    height: 100%;
    color: #b3bbc5;
    &::v-deep {
      .el-form-item {
        margin-bottom: 14px;
        .required {
          color: #ed6a6a;
          width: 4px;
          position: absolute;
          left: -32%;
        }
      }
      .el-input__inner {
        width: 96%;
        text-align: left;
      }
      .el-form-item__error {
        padding-top: 0px;
        position: absolute;
        bottom: -12px;
        margin-left: 15px;
      }
      .el-form-item__label {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #b3bbc5;
      }
      .el-input {
        width: 187px;
      }
      .el-button {
        width: 56px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        padding: 0;
        line-height: 32px;
        box-sizing: border-box;
        .iconfont {
          font-size: 16px;
          color: #979eaa;
        }
      }
    }
  }
  .footer {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    .btn {
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      padding: 0;
      width: 92px;
      height: 32px;
      line-height: 32px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #f8f8f8;
    }
  }
}
</style>
