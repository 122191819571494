<template>
  <div class="cpt-dashborad-videocpt">
    <!-- <div class="track_item" v-for="index of 5" :key="index">
      <span class="isGou"></span>
      <span class="info">20200722 14:26:36</span>
      <span class="operate">查看</span>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cpt-dashborad-videocpt {
  // width: 373px;
  height: 175px;
  background: #171a1c;
  border-radius: 2px;
  padding: 0 8px;
  box-sizing: border-box;
  .track_item {
    // background: #282b33;
    // border: 1px solid #50abff;
    // box-shadow: inset 0 -1px 0 0 #000000;
    height: 29px;
    line-height: 29px;
    .isGou {
      width: 22px;
      display: inline-block;
    }
    .type {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #689eea;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      border-radius: 30%;
      border: #689eea 1px solid;
      margin: 0 5px;
    }
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #eaf6ff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .operate {
      float: right;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #00feff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>
