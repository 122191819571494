<template>
  <div class="cpt-observe-stat-box">
    <div class="stat-box-inner">
      <!-- <div class="filed">
        <div class="label">海拔</div>
        <div class="value">{{ data.altitude || 0 }}</div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <div class="label">高度</div>
        <div class="value">{{ data.height || 0 }}</div>
        <div class="unit">m</div>
      </div> -->
      <div class="filed">
        <div class="label">飞行时间</div>
        <div class="value">{{ data.flyTime || 0 }}</div>
        <div class="unit">min</div>
      </div>
      <div class="filed">
        <div class="label">飞行里程</div>
        <div class="value">
          {{ parseInt(data.flyDistance) || 0 }}
        </div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <div class="label">起点距离</div>
        <div class="value">
          {{ data.distanceToHome || 0 }}
        </div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <div class="label" style="position: relative">
          <div class="battery">
            <span
              :class="parseInt(data.battaryRemain) < 10 ? 'bgRed' : ''"
              style="border-radius: 1px 0 0 1px; margin-left: 0px"
            />
            <span v-if="parseInt(data.battaryRemain) >= 20" />
            <span v-if="parseInt(data.battaryRemain) >= 45" />
            <span v-if="parseInt(data.battaryRemain) >= 70" />
            <span v-if="parseInt(data.battaryRemain) >= 95" style="border-radius: 0 1px 1px 0" />
          </div>
          电量
        </div>
        <div class="value">
          {{ parseInt(data.battaryRemain) | electricity }}
        </div>
        <div class="unit">%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    electricity(value) {
      if (value <= 0) {
        return 0;
      } else if (value < 100) {
        return 100;
      } else {
        return value;
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      num: 60
    };
  }
};
</script>

<style lang="scss" scoped>
.cpt-observe-stat-box {
  height: 45px;
  // position: relative;
  // width: 650px;
  // left: 115px;
  // &::before {
  //   content: " ";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   height: 1px;
  //   background-image: linear-gradient(
  //     270deg,
  //     rgba(0, 117, 221, 0) 0%,
  //     #23b8ff 50%,
  //     rgba(0, 117, 221, 0) 100%
  //   );
  // }
  .stat-box-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 58px;
    .filed {
      display: flex;
      align-items: center;
      // margin-right: 10px;
      .label {
        font-family: Microsoft YaHei;
        font-size: 10px;
        color: #b3bbc5;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        white-space: nowrap;
      }
      .value {
        font-family: UniDreamLED;
        font-size: 24px;
        color: #08ffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin: 0 4px;
        // width: 70px;
        overflow: hidden;
        white-space: nowrap;
      }
      .unit {
        font-family: UniDreamLED;
        font-size: 14px;
        margin-right: 5px;
        color: #c9d2db;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .battery {
    position: relative;
    display: inline-block;
    height: 10px !important;
    line-height: 10px !important;
    width: 23px !important;
    padding: 1px;
    border: 1px solid #979797;
    border-radius: 3px;
    padding: 1px;
    /* overflow: hidden; */
    box-sizing: border-box;
    .bgRed {
      background-color: red !important;
    }
    &:after {
      content: '';
      position: absolute;
      height: 4px;
      width: 2px;
      top: 50%;
      right: -3px;
      transform: translateY(-50%);
      /* border-radius: 0 7px 7px 0; */
      background-color: #979797;
      box-sizing: border-box;
    }
    span {
      background-color: #04cf01;
      width: 15.66%;
      height: 6px;
      float: left;
      margin-left: 1px;
      box-sizing: border-box;
    }
  }
}
</style>
