<script>
import MessageModal from "@/components/common/message-modal/index.vue";
import { AirLine, Control_API, flightTaskAPI, Tg } from "@/api";
import {
  deviceStatusDictionary,
  eagleTaskTypeDictionary,
  executionStatusDictionary,
} from "@/utils/dictionary";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    MessageModal,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      tabList: [
        {
          id: 1,
          label: "常态任务",
          name: "0",
        },
        {
          id: 2,
          label: "定时任务",
          name: "1",
        },
        {
          id: 3,
          label: "周期任务",
          name: "2",
        },
      ],
      flag: true,
      currentTab: "0",
      // 当前任务
      activeTask: "",
      taskList: [], // 任务列表
      userInfo: JSON.parse(window.localStorage.getItem("user_info")),
      // 定时器
      TaskLibraryList: null,
    };
  },
  computed: {
    ...mapState(["openLeftNavSilder"]),
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.currentEagle?.hardId];
    },
    getCurrentEagleTaskType() {
      return eagleTaskTypeDictionary.find(
        (v) => v.label === this.currentEagle.uav.taskType
      )?.value;
    },
  },
  watch: {
    currentEagle(val) {
      if (val) {
        this.getTaskLibraryList();
      }
    },
  },
  mounted() {
    this.getTaskLibraryList();
    // 没两秒刷新
    this.TaskLibraryList = setInterval(() => {
      this.getTaskLibraryList();
    }, 2000);
  },
  methods: {
    // 结束任务
    async finish(row) {
      if (row.taskStatus !== 1) {
        return this.$message.warning("任务暂未执行!");
      }
      const res = await flightTaskAPI.nest_task_end(row.id);
      if (res.code == 200) {
        this.$message.success(res.msg);
        // 把锁打开 监听执行中的任务
        this.flag = true;
        this.getTaskLibraryList();
      }
    },
    finishNestTask() {},
    // 获取任务库列表
    getTaskLibraryList(type, taskStatusList) {
      // this.loading = true;
      // this.taskList = [];
      Control_API.getTaskList({
        taskStatusList: taskStatusList || [0, 1],
        nestId: this.currentEagle.id,
        approveStatusList: [0, 2],
        projectId:
          JSON.parse(localStorage.getItem("subplatformInfo"))?.projectId ||
          undefined,
        target: 1,
      }).then(({ data }) => {
        this.loading = false;
        const TaskList = (data || []).map((v) => ({
          ...v,
          isAutomatic: !!v.nestId,
        }));

        let activeTask = TaskList.filter((v) => v.taskStatus == 1);
        let type = this.currentTab;
        if (activeTask.length > 1 && !this.flag) {
          if (activeTask[0].id != this.activeTask.id) {
            this.flag = true;
          }
        }
        if (activeTask.length && this.flag) {
          // 锁
          this.flag = false;
          type = Number(activeTask[0].type);
          this.currentTab = type + "";
          this.activeTask = activeTask[0];
          // 预览航线
          this.$store.commit("uavApplications/setState", {
            key: "routeId",
            value: activeTask[0].routeId,
          });
          // 选中任务
          this.$emit("setCurrentTaskEvent", activeTask[0]);
        }
        this.taskList = TaskList.filter((v) => {
          if (v.type <= 1) {
            return v.type == type;
          } else if (type == 2) {
            return v.type >= type;
          }
        });
        // if (
        //   this.currentEagle.uav.taskId &&
        //   TaskList.length &&
        //   !taskStatusList
        // ) {
        //   this.$store.commit("uavApplications/setState", {
        //     key: "routeId",
        //     value: activeTask[0].type.routeId,
        //   });
        // }
        // // 当前正在执行的任务
        // if (this.currentEagle.uav.taskId || taskStatusList?.at(0) === 1) {
        //   this.$emit("setCurrentTaskEvent", this.taskList.at(0));
        // }
      });
    },
    // tab切换
    tabChangeEvent() {
      this.loading = true;
      this.taskList = [];
      this.$emit("tabChangeEvent", Number(this.currentTab));
      this.getTaskLibraryList(Number(this.currentTab));
    },
    // 详情弹窗
    showTaskDetailEvent(row) {
      this.$emit("showTaskDetailEvent", row);
    },
    getExecutionStatusDictionary(taskStatus) {
      return executionStatusDictionary.find((v) => v.value === taskStatus)
        ?.label;
    },
    close() {
      this.$emit("close");
    },
    switchChangeEvent(e, row) {
      Control_API.startUavNest({
        id: row.id,
        nestId: this.currentEagle.id,
        start: e,
      })
        .then(({ code }) => {
          if (code === 200) {
            this.$message.success("操作成功");
          } else {
            row.isAutomatic = !e;
          }
        })
        .catch(() => {
          row.isAutomatic = !e;
        });
    },
    // 执行任务

    performTasks(row) {
      if (!this.$uav.currentOperator) {
        const messageValue = {
          title: "安全确认",
          message: `请确认是否接管${this.$uav.name}且进行一键任务操作？`,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          fun: () => {
            Tg.setUavControlOn({
              force: false,
              id: this.$uav.id,
              uid: this.userInfo.id,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success(`已成功接管${this.$uav.name}`);
                this.$store.commit("uavApplications/setState", {
                  key: "uav",
                  value: {
                    ...this.$uav,
                    currentOperator: this.userInfo.id,
                  },
                });
                this.lineAndDirective(row);
              }
            });
          },
        };
        this.messageBox(messageValue);
      } else if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的
        const messageValue = {
          title: "提示",
          message: `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`,
          confirmButtonText: "好的,知道了",
        };
        // 不是本账号接管的无人机
        return this.messageBox(messageValue);
      } else {
        this.$confirm("请确认是否进行一键任务操作并执行一键起飞?", "安全确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        }).then(() => {
          this.lineAndDirective(row);
        });
      }
      this.$emit("performTasks", row);
    },
    messageBox({ title, message, confirmButtonText, cancelButtonText, fun }) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      const value = {
        title,
        message,
        center: true,
      };
      if (confirmButtonText) {
        value.confirmButtonText = confirmButtonText;
      }
      if (cancelButtonText) {
        value.cancelButtonText = "取消";
        value.showCancelButton = true;
      }
      this.$msgbox({
        ...value,
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    fly_control_unlock(key) {
      const seed = Date.now();
      this.$store.commit("uavApplications/setState", {
        key,
        value: seed,
      });
    },
    // 解析航线并发出指令
    async lineAndDirective(row) {
      const that = this;
      // 当前机库状态是否空闲
      if ([0, 8].includes(this.mqttDataSet["2065"].data.processStatus)) {
        // 更新任务状态
        flightTaskAPI
          .editFlight({
            ...row,
            changeSchedul: false,
            deviceHardId: this.$uav.hardId,
            taskStatus: 1,
          })
          .then((res) => {
            if (res.code === 200) {
              // 获取航线跟架次号
              Promise.all([
                AirLine.routeDetail({
                  id: row.routeId,
                }),
                Control_API.getFlightSortic({
                  taskId: row.id,
                  deviceHardId: this.$uav.hardId,
                }),
              ]).then(([routeRes, flightSorticRes]) => {
                const flightObj = JSON.parse(routeRes.data.content);
                const waypointList = flightObj.content.map((v) => ({
                  altitude: v.alt,
                  coordinate: {
                    latitude: v.latitude,
                    longitude: v.longitude,
                  },
                  frame: 3,
                  stay: 0.0,
                  speed: v.speed,
                  waypointActions: v.actions,
                }));
                this.$store.dispatch("MMCMQTT/publish", {
                  topic: "PROCESS/OBTAIN/" + this.currentEagle.hardId,
                  data: {
                    cmdControlType: 100004,
                    uavDeviceId: this.$uav.hardId,
                    wayLineObj: {
                      taskId: row.id,
                      flightSortiesID: flightSorticRes.data,
                      autoFlightSpeed: flightObj.line.baseSpeed,
                      waypointList,
                      finishedAction: "GO_HOME",
                      headingMode: "AUTO",
                      isExitMissionOnRCSignalLostEnabled: true,
                      maxFlightSpeed: 12,
                    },
                  },
                  callback() {
                    that.$store.commit("uavApplications/setState", {
                      key: "routeId",
                      value: row.routeId,
                    });
                    that.$emit("setCurrentTaskEvent", row);
                    that.getTaskLibraryList(Number(that.currentTab), [1, 0]);
                    that.fly_control_unlock("fly_control_unlock");
                    that.$message.success("操作成功");
                  },
                });
              });
            }
          });
      } else {
        // 获取当前机库状态
        const statusItem = deviceStatusDictionary.find(
          (item) => item.value === this.mqttDataSet["2065"].data.processStatus
        );
        this.$message.warning(statusItem?.label || "");
      }
    },
    //   任务选中
    handleCurrentChange(val) {
      if (this.currentTab === "0" && val.taskStatus === 0) {
        this.$store.commit("uavApplications/setState", {
          key: "routeId",
          value: val.routeId,
        });
        this.$message.info(`已选中任务:${val.taskName}`);
        this.$emit("setCurrentTaskEvent", val);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.TaskLibraryList);
  },
};
</script>

<template>
  <div class="task-library-modal">
    <message-modal
      :show="show"
      title="任务库"
      :width="560"
      :position-top="54"
      :position-left="openLeftNavSilder ? 450 : 70"
      @close="close"
    >
      <div class="task-library">
        <div class="task-library-tabs">
          <el-tabs v-model="currentTab" @tab-click="tabChangeEvent()">
            <el-tab-pane
              v-for="v in tabList"
              :key="v.id"
              :label="v.label"
              :name="v.name"
            />
          </el-tabs>
        </div>
        <div class="task-library-table">
          <el-table
            v-loading="loading"
            element-loading-text="加载数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.9)"
            style="width: 100%"
            :data="taskList"
            :highlight-current-row="currentTab === '0'"
            border
            height="284"
            @current-change="handleCurrentChange"
          >
            <el-table-column
              label="任务名称"
              align="center"
              width="80px"
              prop="taskName"
            />
            <el-table-column prop="taskStatus" label="执行状态" align="center">
              <template slot-scope="scope">
                <span>{{
                  getExecutionStatusDictionary(scope.row.taskStatus)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="currentTab != 2"
              prop="expectedtime"
              label="执行时间"
              align="center"
              width="200px"
            />
            <el-table-column
              v-if="currentTab == '2'"
              prop="expectedtime"
              label="有效期时间"
              width="200px"
              align="center"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.expectedtime }}-</div>
                <div>{{ scope.row.scheduledEnd }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="currentTab !== '0'"
              prop="nestId"
              label="启动自动"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isAutomatic"
                  active-color="#50ABFF"
                  inactive-color="#5C6881"
                  @change="(e) => switchChangeEvent(e, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  v-hover
                  v-pms="['飞行任务', '详情']"
                  effect="dark"
                  content="详情"
                  placement="top"
                >
                  <img
                    src="~@/assets/image/icons/details.png"
                    style="color: #a16869; width: 26px; height: 26px"
                    @click.stop="showTaskDetailEvent(scope.row)"
                  />
                </el-tooltip>
                <el-tooltip
                  v-if="currentTab === '0' && scope.row.taskStatus === 0"
                  v-pms="['飞行任务', '一键任务']"
                  v-hover
                  effect="dark"
                  finish
                >
                  <img
                    src="~@/assets/image/icons/one_click_task.png"
                    style="color: #a16869; width: 26px; height: 26px"
                    @click.stop="performTasks(scope.row)"
                  />
                </el-tooltip>
                <el-tooltip
                  v-hover
                  effect="dark"
                  content="结束任务"
                  placement="top"
                >
                  <img
                    src="./finish.png"
                    class="w22 h22 mb1"
                    style="color: #a16869"
                    @click.stop="finish(scope.row)"
                  />
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.task-library {
  padding: 8px;
  &-tabs {
  }
  &-table {
    background-color: #0a0b0d;
  }
}
::v-deep {
  .el-tabs {
    background-color: #2c313b;
  }
  .el-tabs__item {
    width: calc(100% / 3);
    // font-size: 13px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .el-tabs__nav {
    float: none;
  }
  .el-table__body tr.current-row > td.el-table__cell {
    background-color: #50abff;
  }
  //.el-table__body tr.current-row{
  //  border: 1px solid #50ABFF;
  //}
}
</style>
