<template>
  <div class="select-tack">
    <div v-interact class="header">
      <span class="title">选择任务</span>
      <span class="iconfont icon-guanbi" @click="$emit('fn')" />
    </div>
    <div class="body">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="选择任务" required>
          <el-select v-model="taskIndex" placeholder="请选择任务名称">
            <!-- filterable -->
            <el-option
              v-for="(item, i) in selectTask_options"
              :key="item.id"
              :label="item.taskName"
              :value="i"
              value-key
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="form_btn">
      <div class="btn_cancel" @click="$emit('fn')">取消</div>
      <div class="btn_send" @click="sendTask()">发送任务至无人机</div>
    </div>
  </div>
</template>

<script>
import { flightTaskAPI } from '@/api';
export default {
  inject: ['change_active', 'get_flight_info'],
  name: 'SelectTack',

  data() {
    return {
      taskIndex: '',
      selectTask_options: [],
      page: {
        num: 1,
        size: 999
      }
    };
  },

  computed: {
    selectedTaskItem() {
      return this.selectTask_options[this.taskIndex];
    }
  },

  async mounted() {
    await this.getTaskList();
  },

  methods: {
    async sendTask() {
      const data = await flightTaskAPI.startTask({
        id: this.selectedTaskItem.id
      });
      if (data.status == 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '发送成功！',
          duration: 1000
        });
        this.$emit('fn');
        this.get_flight_info(this.selectedTaskItem);
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: data.message,
          duration: 1000
        });
      }
    },
    // 获取任务列表
    async getTaskList() {
      const resp = await flightTaskAPI.getTaskSelect();
      console.log('getTaskList....', resp);
      this.selectTask_options = resp.data.data;
    }
  }
};
</script>

<style lang="scss" scoped>
.select-tack {
  width: 323px;
  height: 170px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  bottom: 300px;
  right: 100px;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .body {
    height: calc(100% - 85px);
    width: 317px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;
    .el-form {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      letter-spacing: 0;
      &::v-deep {
        .el-form-item__label {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #b3bbc5;
        }
        .el-input {
          width: 227px;
          height: 32px;
          .el-input__inner {
            height: 100%;
            width: 100%;
            background: #16181b;
            border-radius: 8px;
            border: none;

            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
          }
          .el-input__icon {
            line-height: 32px;
            color: #1eb9fa;
          }
        }
      }
    }
  }
  .form_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    width: 317px;
    .btn_cancel,
    .btn_send {
      box-sizing: border-box;
      height: 32px;
      padding: 0 20px 0 20px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .btn_cancel {
      padding: 0 30px 0 30px;
    }
  }
}
</style>
