import FileSaver from 'file-saver';

const model = (reportInfoDetail) => {
  return `
    <!DOCTYPE html>
      <html>

<head>
  <style>
  table {
    margin: 0 auto;
    text-align: center; /*设置单元格文本居中*/
    border-collapse: collapse; /*合并单元格边框*/
    td,
    th {
      border: 1px solid #ccc; /*设置单元格边框*/
      padding: 10px;
    }
  }
  </style>
</head>

<body>
<table border>
<caption>
  飞行报告
</caption>
<tbody class="tbody">
  <tr>
    <td>发起机构</td>
    <td>${reportInfoDetail.organizationName || ''}</td>
    <td>处理机构</td>
    <td>${reportInfoDetail.organizationName || ''}</td>
  </tr>
  <tr>
    <td>发起需求人</td>
    <td>${reportInfoDetail.userName || ''}</td>
    <td>任务流程</td>
    <td>${reportInfoDetail.processName || ''}</td>
  </tr>
  <tr>
    <td>任务名称</td>
    <td>${reportInfoDetail.taskName || ''}</td>
    <td>执飞类型</td>
    <td>${reportInfoDetail.typeName || ''}</td>
  </tr>
  <tr>
    <td>无人机/机库编号</td>
    <td>${reportInfoDetail.hardId || ''}</td>
    <td>详细作业位置</td>
    <td>${reportInfoDetail.startPosition + '=>' + reportInfoDetail.endPosition || ''}    </td>
  </tr>
  <tr>
    <td>飞行架次</td>
    <td>${reportInfoDetail.sortie || ''}</td>
    <td>飞行时间</td>
    <td>${reportInfoDetail.startTime + '=>' + reportInfoDetail.endTime || ''}</td>
  </tr>
  <tr>
    <td>飞行里程(m)</td>
    <td>${reportInfoDetail.flightTime}</td>
    <td>飞行总时间(min)</td>
    <td>${reportInfoDetail.flightTime}</td>
  </tr>
  <tr>
    <td>识别总数</td>
    <td colspan="3">${reportInfoDetail.aiCount || ''}</td>
  </tr>
  <tr>
    <td>识别类型</td>
    <td>图片</td>
    <td colspan="2">预警时间</td>
  </tr>
  <!-- 循环 -->
  ${reportInfoDetail.imageList.map((i) => {
    return `<tr>
      <td>${i.type || ''}</td>
      <td><img width='240' height='160' src=${i.file} /></td>
      <td colspan="2">${reportInfoDetail.addtime || ''}</td>
    </tr>`;
  })}
  <tr>
    <td>任务总结</td>
    <td colspan="3">识别总次数共${reportInfoDetail.aiCount || ''}次,识别算法调用${
  reportInfoDetail.aiTypeCount || ''
}个,识别目标物体共${reportInfoDetail.aiGoalCount || ''}处</td>
  </tr>
</tbody>
</table>
</body >
</html >
  `;
};

const loadFile = (reportInfoDetail) => {
  return new Promise(function (resolve, reject) {
    const html = model(reportInfoDetail);
    const blob = new Blob([html], { type: 'application/msword;charset=utf-8' });
    FileSaver.saveAs(blob, '信息管理文件.doc');
    resolve();
  });
};

export { loadFile };
