<template>
  <div class="database">
    <div v-interact class="database-box">
      <div class="database-head">
        <div class="left-menu">
          <div
            class="title"
            :class="{ active: library_status === 1 ? true : false }"
            @click="library_status = 1"
          >视图</div>
          <div
            class="title"
            :class="{ active: library_status === 2 ? true : false }"
            @click="library_status = 2"
          >机载</div>
        </div>
        <i class="el-icon-close" @click="onClose" />
      </div>
      <div class="database-body" v-if="library_status == 1">
        <div class="database-right">
          <div class="database-right-head">
            <div class="database-right-head-function">
              <div style="margin-right: 8px">筛选:</div>
              <el-select
                v-model="active"
                style="width: 110px"
                placeholder="请选择"
                @change="changeActive"
              >
                <el-option
                  v-for="item in uav_status_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div
              v-if="active === 2"
              class="database-right-head-leading-out"
              :class="{ disable: AiLeadingOut ? false : true }"
              @click="leadingOut"
            >飞行报告导出</div>
          </div>
          <div class="database-right-body">
            <div v-show="active == 1" class="database-right-body-list">
              <div
                v-for="item in rightBoxImgList"
                :key="item.id"
                class="database-right-body-list-item"
              >
                <el-image
                  style="width: 145px; height: 95px"
                  class="item-img"
                  :src="item.image"
                  :preview-src-list="[item.image]"
                  @click="addAttribute"
                  crossorigin="anonymous"
                  lazy
                />
                <div class="item-content">
                  <span class="content-label">时间:{{ item.addtime }}</span>
                  <span class="content-label">名称:{{ item.name }}</span>
                  <span class="content-label">架次:{{ item.sortie }}</span>
                </div>
              </div>
            </div>
            <div v-show="active == 2" class="database-right-body-list">
              <div
                v-for="item in rightBoxImgList"
                :key="item.id"
                class="database-right-body-list-item"
              >
                <el-image
                  style="width: 145px; height: 95px"
                  :src="item.image"
                  lazy
                  class="item-img"
                  :preview-src-list="[item.image]"
                  crossorigin="anonymous"
                  @click="addAttribute"
                />

                <div class="item-content">
                  <span class="content-label" :title="item.addtime">时间:{{ item.addtime }}</span>
                  <span class="content-label" :title="item.name">名称:{{ item.name }}</span>
                  <span class="content-label" :title="item.sortie">架次:{{ item.sortie }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="database-body" v-if="library_status == 2">
        <div class="database-right">
          <div class="select tr mb10">
            <div class="btns cp" :class="filetypev2 == 0 ? 'bg' : ''" @click="changephotoType(0)">图片</div>
            <div class="btns cp" :class="filetypev2 == 1 ? 'bg' : ''" @click="changephotoType(1)">视频</div>
            <!-- <span class="iconfont icon-gengxin" @click="reset"></span> -->
          </div>
          <div class="database-right-body">
            <!-- <p v-if="filetypev2==1" class="mb19 cf mt10">
              如遇视频无法播放，请
              <a
                href="http://32.128.6.52:4500/11.11平台部署/ChromeStandaloneSetup64.exe"
              >下载最新谷歌</a>
            </p> -->
            <div v-if="filetypev2==0" class="database-right-body-list mt10">
              <div v-for="item in photoList" :key="item.id" class="database-right-body-list-item">
                <el-image
                  style="width: 145px; height: 95px"
                  class="item-img"
                  :src="VUE_APP_LOOK_URL + item.filePath"
                  :preview-src-list="[VUE_APP_LOOK_URL + item.filePath]"
                  @click="addAttribute"
                  crossorigin="anonymous"
                  lazy
                />
                <div class="item-content">
                  <span class="content-label">时间:{{ item.timeUsec }}</span>
                  <span class="content-label">名称:{{ item.deviceId }}</span>
                </div>
              </div>
            </div>
            <div v-if="filetypev2==1">
              <div class="database-right-body-list-item" style="display: flex;
              justify-content: space-between;
              flex-wrap: wrap;">
                <video
                crossorigin="anonymous"
                class="mb10"
                  v-for="item in photoList"
                  style="width: 150px; height: 95px"
                  controls
                  :src="VUE_APP_LOOK_URL + item.filePath"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="library_status == 1">
      <div v-if="video" class="video-box">
        <div class="video-head">
          视频
          <span class="el-icon-close" @click="video = null" />
        </div>
        <video crossorigin="anonymous" v-interact class="plays" controls width="250">
          <source :src="video" type="video/hls" />
          <source :src="video" type="video/mp4" />Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import { Tg } from "@/api";
import { loadFile } from "./wordModel";
export default {
  inject: ["change_active"],
  data() {
    return {
      photoList: [],
      library_status: 1,
      uav_status_options: [
        {
          value: 2,
          label: "AI图片",
        },
        {
          value: 1,
          label: "图片",
        },
      ],
      uav_status_options2: [
        {
          value: 0,
          label: "图片",
        },
        {
          value: 1,
          label: "视频",
        },
      ],
      open: true, // 显示弹框
      active: 2, // 控制右侧的tab切换
      leftActive: null, // 左侧列表内容选中
      sortieBoxList: [], // 左侧列表数据
      rightBoxImgList: [], // 右侧图片列表数据
      rightBoxVideoList: [], // 右侧视频列表数据
      searchName: "", // 搜索架次名称
      video: null, // 当前点击的视频
      AiLeadingOut: null,
      flightSortie: null,
      filetype: 0, // 图片还是视频
      setIntervalTime: null,
      filetypev2: 0,
    };
  },
  computed: {
    // 无人机选择
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    // 实时数据
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
  },
  watch: {
    library_status(val) {
      if (val == 2) {
        this.setIntervalJZ();
      } else {
        clearInterval(this.setIntervalTime);
        this.setIntervalTime = null;
      }
      // this.rightBoxImgList = [];
    },
  },
  async created() {
    await this.changeActive(2);
    await this.getSortieList();

    // AI布防等触发更新结果
    this.$bus.$on("updateAIResult", () => {
      console.log("AI布防等触发更新结果");
      this.getImgList();
    });
  },
  mounted() {
    this.VUE_APP_LOOK_URL = process.env.VUE_APP_LOOK_URL;
  },
  beforeDestroy() {
    if (this.setIntervalTime) {
      clearInterval(this.setIntervalTime);
    }
  },
  methods: {
 changephotoType(num) {
      this.photoList =[]
      this.getjz();
     setTimeout(() => {
      this.filetypev2 = num;
     }, 30);
    },
    setIntervalJZ() {
      if (this.setIntervalTime) {
        clearInterval(this.setIntervalTime);
      }
      this.getjz();
      this.setIntervalTime = setInterval(() => {
        this.getjz();
      }, 4000);
    },
    async getjz() {
      let data = {
        deviceId: this.$uav.hardId,
        finish: 1,
        pageNo: 1,
        pageSize: 200,
        type: this.filetypev2,
        sort: 1,
      };
      let res = await Tg.devicephoto(data);
      if (this.photoList.length != res.data.total) {
        this.photoList = res.data.list;
      } else {
        // console.log('数据重复');
      }
      return;
      let blob = null;
      // this.photoList.forEach(async (val) => {
      //   // let res = await API.FCKERNEL.fileUrl('http://32.128.6.70:20251'+ val.filePath)//48
      //   let res = await API.FCKERNEL.fileUrl(
      //     process.env.VUE_APP_LOOK_URL + val.filePath
      //   ); //52
      //   if (this.filetype == 0) {
      //     // blob = new Blob([res],{type: "image/jpeg"})
      //     val.jsonFilePath = process.env.VUE_APP_IMG_URL + val.filePath;
      //   } else {
      //     blob = new Blob([res], { type: "video/mp4" });
      //     val.jsonFilePath = window.URL.createObjectURL(blob);
      //   }
      // });
    },
    async getSortieList() {
      const res = await Tg.getSortieList({
        // 硬件id
        deviceHardId: this.$uav.hardId,
      });
      if (res.code === 200 && res.data.records.length) {
        this.AiLeadingOut = res.data.records[0];
      }
    },
    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        const image = new Image();
        image.setAttribute("crossOrigin", "Anonymous");
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
            imgObj: this,
          });
        };
        image.onerror = function () {
          reject(new Error("error"));
        };
        image.src = url;
      });
    },
    getImgArr(reportInfoDetail, canvasEle, ctx, image) {
      return new Promise(function (resolve, reject) {
        try {
          const obj = reportInfoDetail.sortieImageList.map((v) =>
            JSON.parse(v.remark)
          );
          const obj1 = {};
          obj.map((e) => {
            if (e && Object.keys(e)) {
              Object.keys(e).map((ele) => {
                if (!obj1[ele]) {
                  obj1[ele] = [];
                }
                if (Object.keys(obj1).includes(ele)) {
                  obj1[ele].push(e[ele]);
                } else {
                  obj1[ele].push(e[ele]);
                }
              });
            }
          });
          const imgArr = [];
          for (const key in obj1) {
            obj1[key].map((element) => {
              setTimeout(() => {
                ctx.clearRect(0, 0, image.width, image.height);
                ctx.drawImage(image.imgObj, 0, 0);
                element.map((value) => {
                  ctx.strokeStyle = `rgb(${value.color.join(",")})`;
                  ctx.font = `rgb(${value.color.join(
                    ","
                  )}) 14px Microsoft YaHei`;
                  ctx.fillStyle = `rgb(${value.color.join(",")})`;
                  ctx.fillText(
                    key,
                    value.bbox[0] - 10,
                    value.bbox[1] - 10,
                    100
                  );
                  ctx.strokeRect(
                    value.bbox[0],
                    value.bbox[1],
                    value.bbox[2] - value.bbox[0],
                    value.bbox[3] - value.bbox[1]
                  );
                });
                imgArr.push({
                  type: key,
                  file: canvasEle.toDataURL("image/png"),
                });
              });
            });
          }
          reportInfoDetail.imageList = imgArr;
          setTimeout(() => {
            loadFile(reportInfoDetail);
          });
          resolve();
        } catch (error) {
          console.log(error);
        }
      });
    },
    async drawCanvas() {
      if (!this.flightSortie) {
        return this.$message.warning("暂无飞行架次！");
      }
      const res = await Tg.getExportToWordForSortie({
        id: this.flightSortie || "",
      });
      const reportInfoDetail = res.data;
      const url =
        "https://pad-video-x.oss-cn-shenzhen.aliyuncs.com/cloudApi/1699348944679_blob.png";
      const image = await this.getImageSize(url);
      const can = document.createElement("canvas");
      can.id = "canvas";
      can.width = image.width;
      can.height = image.height;
      can.style.position = "fixed";
      can.style.left = "0";
      can.style.top = "0";
      document.body.appendChild(can);
      const canvasEle = document.getElementById("canvas");
      const ctx = canvasEle.getContext("2d");
      ctx.drawImage(image.imgObj, 0, 0);
      await this.getImgArr(reportInfoDetail, canvasEle, ctx, image);
    },
    addAttribute() {
      this.$nextTick((e) => {
        document
          .querySelector(".el-image-viewer__canvas")
          .childNodes[0].setAttribute("crossorigin", "anonymous");
      });
    },
    openVideo(item) {
      item.playUrl = "https";
      this.video = item.playUrl;
    },
    async download() {
      if (!this.rightBoxImgList[0]?.sortie) {
        return false;
      }
      const res = await Tg.getExportToWordForSortie({
        id: this.rightBoxImgList[0].sortie || null,
      });
      const content = res;
      const blob = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
      });
      const fileName = "飞行报告";
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    async getflightSortie() {
      const res = await Tg.uavLastData({
        hardId: this.$uav.hardId,
        appId: 87878,
      });
      if (res.data != "DATA_NOT_EXIST") {
        this.flightSortie = res.data.deviceData.data.uavInfo.flightSortie;
      }
    },
    async leadingOut() {
      // // 导出成果
      // await this.getflightSortie();
      this.download();
    },
    async onChangeSortie(item) {
      this.leftActive = item.id;
      await this.getImgList(item.id);
      await this.getVideoList(item.id);
    },
    onClose() {
      this.$store.commit("uavApplications/setState", {
        key: "showViewLibrary",
        value: false,
      });
    },
    async changeActive(status) {
      this.active = status;
      await this.getImgList();
    },
    async getImgList() {
      // sortie: this.uavRealTimeData.flightSortie,
      const res = await Tg.getSortieImage({
        imageType: this.active,
        page: 1,
        pageSize: 100,
      });
      if (res.code === 200) {
        this.rightBoxImgList = res.data.records;
      }
    },
    async getVideoList(id) {
      const res = await Tg.getSortieVideo({ id, page: 1, pageSize: 10 });
      if (res.code === 200) {
        this.rightBoxVideoList = res.data.records;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.database {
  /* background: rgba(19, 21, 24, 0.7); */
  background: #000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  /* border: 2px solid #2e63dc; */
  width: 345px;
  height: 315px;
  position: absolute;
  text-align: left;
  padding: 0;
  margin: 0;
  bottom: 20px;
  z-index: 2;
  right: 65px;
  .database-box {
    display: flex;
    flex-direction: column;
    height: 315px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 10px;
    border: 1px solid rgba(52, 223, 255, 1);
    backdrop-filter: blur(4px);
    padding: 4px;
    .database-head {
      height: 29px;
      border-radius: 9px 9px 0px 0px;
      .left-menu {
        display: flex;
        .title {
          position: relative;
          cursor: pointer;
          width: 80px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #0a0b0d;
          font-size: 14px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #a4ff64;
          border-radius: 4px 4px 0 0;
          &.active::after {
            content: "一";
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .database-body {
      display: flex;
      background: #0a0b0d;
      border-radius: 0px 4px 4px 4px;
      padding: 8px;
      flex-wrap: wrap;
      flex: 1;
      height: 1px;
      .database-left {
        width: 20%;
        .database-left-head {
          background-color: #23252a;
          color: #ccc;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
        .database-left-body {
          text-align: center;
          color: #ccc;
          height: 360px;
          overflow: auto;
          .database-left-body-item {
            height: 40px;
            line-height: 40px;
            white-space: nowrap; //禁止换行
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //显示省略号
            border: 1px solid transparent;
            &:hover {
              cursor: pointer;
              border: 1px solid #ccc;
            }
            &.active {
              border: 1px solid #ccc;
            }
            &:nth-child(odd) {
              background-color: #131519;
            }
            &:nth-child(even) {
              background-color: #090b0c;
            }
          }
        }
      }
      .database-right {
        width: 100%;
        height: 270px;
        overflow-y: auto;
        .database-right-head {
          display: flex;
          justify-content: space-between;
          height: 50px;
          color: #ccc;
          text-align: center;
          line-height: 50px;
          .database-right-head-function {
            display: flex;
            .function-item {
              width: 140px;
              border-radius: 5px 5px 0 0;
              //去除双击选中文字的效果
              /* 火狐 */
              -moz-user-select: none;
              /* Safari 和 欧朋 */
              -webkit-user-select: none;
              /* IE10+ and Edge */
              -ms-user-select: none;
              /* Standard syntax 标准语法(谷歌) */
              user-select: none;
              cursor: pointer;
              &.active {
                background-color: #23252a;
              }
            }
          }
          .database-right-head-leading-out {
            margin-right: 30px;
            width: 100px;
            height: 32px;
            line-height: 32px;
            background: #1890ff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            //去除双击选中文字的效果
            /* 火狐 */
            -moz-user-select: none;
            /* Safari 和 欧朋 */
            -webkit-user-select: none;
            /* IE10+ and Edge */
            -ms-user-select: none;
            /* Standard syntax 标准语法(谷歌) */
            user-select: none;
            &.disable {
              cursor: not-allowed;
              background-color: #ccc;
              color: #fff;
              pointer-events: none;
            }
          }
        }
        .select {
          display: flex;
          align-items: center;
          .btns {
            margin-right: 16px;
            width: 64px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            background: url("~@/assets/images/zc.png") no-repeat;
            background-size: 100% 100%;
          }

          .bg {
            background: url("~@/assets/images/qx.png") no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .database-right-body {
          overflow-y: auto;
          .database-right-body-list {
            display: flex;
            flex-wrap: wrap;
            .database-right-body-list-item {
              width: 152px;
              height: 155px;
              background-color: #30323b;
              border-radius: 5px;
              margin: 0 4px 10px 0;

              .item-img {
                width: 208px;
                height: 134px;
                padding: 5px 5px 0 5px;
                cursor: pointer;
                &.play {
                  font-size: 80px;
                  text-align: center;
                  line-height: 120px;
                  color: #ccc;
                }
              }
              .item-content {
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                color: #ccc;
                font-size: 12px;
                .content-label {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .database-right-body-video {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  .video-box {
    width: 500px;
    height: 300px;
    position: fixed;
    left: 50%;
    top: 50%;
    .video-head {
      width: 100%;
      height: 30px;
      line-height: 30px;
      color: #ccc;
      background-color: #222;
    }
    .plays {
      width: 500px;
      height: 300px;
    }
  }
}
.qwer {
  position: fixed;
  width: 800px;
  height: 800px;
  bottom: 50%;
  left: 50%;
  background-color: #fff;
  table {
    margin: 0 auto;
    text-align: center; /*设置单元格文本居中*/
    border-collapse: collapse; /*合并单元格边框*/
    td,
    th {
      border: 1px solid #ccc; /*设置单元格边框*/
      padding: 10px;
    }
  }
}
</style>
<style lang="scss">
.el-image__placeholder {
  background: url("./loading.gif") no-repeat 50% 50% !important;
  background-size: 50% !important;
}
</style>
